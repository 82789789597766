<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="info">
        <img src="@/assets/ps.png" />
        <div class="detail">
          <div class="name">{{data.nick_name}}{{on_duty}}</div>
          <div class="tel">{{data.mobile}}</div>
        </div>
      </div>
      <div class="list">
        <div class="listitem" @click="onScan">
          <img src="@/assets/c1.png" class="icon" />
          <div class="text">扫一扫</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      
      <div class="list list2">
        <div class="listitem">
          <img src="@/assets/c3_1.png" class="icon" />
          <div class="text">所在场所</div>
          <div class="right">{{site_code}}</div>
        </div>
        <div class="listitem">
          <img src="@/assets/c2_1.png" class="icon" />
          <div class="text">负责岗位</div>
          <div class="right">{{user_type}}</div>
        </div>
      </div>
      <div v-if="typeflag">
      <div class="list list2">
        <div class="listitem" @click="onLookEwm">
          <img src="@/assets/ewmico.png" class="icon" />
          <div class="text">查看二维码</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>    
      <div class="list list2">
        <div class="listitem" @click="onSetPass">
          <img src="@/assets/szmm.png" class="icon" />
          <div class="text">设置密码</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>  
      </div>  
      
      
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      data: {
        // 1-管理员 2-负责人 3-游客
        /*site_code: '测试场所',
        user_type: '大厨',
        nick_name: '李某某',
        mobile: '13359665321'*/
      },
      typeflag:false,
      site_code: '',
      user_type: '',
      on_duty: '',
      clientcode: '',
      hrefurl:'',
      openid:''
    };
  },
  methods: {
    onScan() {
     
      window.wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: res => {
          let result = res.resultStr;
          this.scanResult(result);
        },
        error: res => {
          Dialog({ message: res });
        }
      });
    },
    async scanResult(code) {
      let params = qs.parse(code.split('?')[1]);
      let { SiteCode, ClientID } = params || {};
      if (SiteCode && ClientID) {
        this.$router.replace({path:'/refueling/input_pass', query:{client_id:ClientID,stie_code:SiteCode}});
        /*let { data } = await this.axios.post(
          '/ctid/JY_ITlService/jy_bdby',
          qs.stringify({
            client_id:ClientID
          })
        );
        if (data.flag === '0') {
          Toast(data.message);
          //this.$router.replace('/assistregister/register_1?hrefurl='+this.hrefurl);
        }else{
          Toast(data.message);       
        }*/
      } else {
          Dialog('二维码解析失败');
      }
    },
    async onLookEwm() {
      this.$router.replace({path:'/refueling/ewm_detail', query:{clientcode:this.clientcode,site_code:this.site_code}});         
    },
    async onSetPass() {
      this.$router.replace({path:'/refueling/bind_post', query:{}}); 
    },

    async getType() {
      /*let { data } = await this.axios.post(
        '/ctid/tlService/usertypes',
        qs.stringify({})
      );
      if (data.flag === '1') {
        this.typeflag = true;    
      }
      this.typeflag = true;*/ 
    }
  },
  async created() { 
    let openid = this.$route.query.openid;
    if (openid !== null && openid !== undefined && openid.length !== 0) {
      window.sessionStorage.setItem('openid', openid); 
    }
    //this.openid = window.sessionStorage.getItem('openid');
    //this.getType();

    this.hrefurl = location.href;
    window.sessionStorage.setItem('hrefurl', this.hrefurl);
    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: location.href
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });

    let { data } = await this.axios.get('/ctid/JY_ITlService/jy_loadcustomer');
    if (data.flag === '0') {
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 

      window.sessionStorage.setItem('userinfo', JSON.stringify(testobj));
      this.data = testobj;
      if(this.data.user_type === '0'){
        this.site_code = '无';
        this.user_type = '游客';
        this.on_duty = '';
        this.clientcode = '';
      }else if(this.data.user_type === '1'){
        this.typeflag = true;
        this.site_code = this.data.site_code;
        this.user_type = '超管';
        this.on_duty = '';
        this.clientcode = this.data.client_id;
      }else if(this.data.user_type === '2'){
        this.site_code = this.data.site_code;
        this.user_type = '加油员';
        if(this.data.user_id === '1'){
          this.on_duty = '（在岗）';
        }else{
          this.on_duty = '（不在岗）';
        }       
        this.clientcode = this.data.client_id;
      }else if(this.data.user_type === '3'){
        this.typeflag = true;
        this.site_code = this.data.site_code;
        this.user_type = '超管和加油员';
        if(this.data.user_id === '1'){
          this.on_duty = '（在岗）';
        }else{
          this.on_duty = '（不在岗）';
        }
        this.clientcode = this.data.client_id;
      }
    }

    


  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 460px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 50px;
    }
  }
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 46px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 46px;
      color: #fff;
      font-size: 32px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>